import Card from "@components/common/Card";
import FarmList from "@components/features/MyPage/Order/FarmList";

const FarmListPage = () => (
  <Card title="농장 체험 내역" w="100%" h="fit-content" p="10">
    <FarmList />
  </Card>
);

export default FarmListPage;
