import Register from "@components/features/RegisterPage/Register";
import RegisterBackground from "@components/features/RegisterPage/RegisterBackground";

const RegisterPage = () => (
  <RegisterBackground>
    <Register />
  </RegisterBackground>
);

export default RegisterPage;
