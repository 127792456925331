import { Category } from "@type/index";

const mockCategory: Category[] = [
  {
    id: 1,
    name: "채소_열매채소",
  },
  {
    id: 2,
    name: "채소_뿌리채소",
  },
  {
    id: 3,
    name: "채소_잎채소",
  },
  {
    id: 4,
    name: "채소_기타채소",
  },
  {
    id: 5,
    name: "과일_열매과일",
  },
  {
    id: 6,
    name: "과일_통과일",
  },
  {
    id: 7,
    name: "과일_새척과일",
  },
];

export default mockCategory;
