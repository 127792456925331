const size = {
  footer: {
    h: "450px",
    px: "200px",
  },
  header: {
    h: "80px",
  },
} as const;

export default size;
