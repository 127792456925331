import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalContentProps,
} from "@chakra-ui/react";

type BasicModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
} & ModalContentProps;

const BasicModal: React.FC<BasicModalProps> = ({ isOpen, onClose, children, ...props }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay>
      <ModalContent {...props}>
        <ModalHeader>{/* Modal Title */}</ModalHeader>
        <ModalCloseButton _hover={{ bg: "#FFFFFF" }} />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </ModalOverlay>
  </Modal>
);

export default BasicModal;
