import EmailLogin from "@components/features/RegisterPage/EmailLogin";
import RegisterBackground from "@components/features/RegisterPage/RegisterBackground";

const EmailLoginPage = () => (
  <RegisterBackground>
    <EmailLogin />
  </RegisterBackground>
);

export default EmailLoginPage;
