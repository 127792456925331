import Card from "@components/common/Card";

import ReviewList from "@components/features/MyPage/Order/ReviewList";

const ReviewListPage = () => (
  <Card title="리뷰 관리" w="100%" h="fit-content" p="10">
    <ReviewList />
  </Card>
);

export default ReviewListPage;
