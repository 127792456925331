import Card from "@components/common/Card";
import Delivery from "@components/features/MyPage/Info/Delivery";

const DeliveryPage = () => (
  <Card title="배송지 수정" w="100%" h="fit-content" p="10">
    <Delivery />
  </Card>
);

export default DeliveryPage;
