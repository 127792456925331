import Card from "@components/common/Card";
import Withdraw from "@components/features/MyPage/Info/Withdraw";

const WithdrawPage = () => (
  <Card title="회원 탈퇴" w="100%" h="fit-content" p="10">
    <Withdraw />
  </Card>
);

export default WithdrawPage;
